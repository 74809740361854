"use client";

import { handleImgResponse } from "@/utils/utility";
import Image from "next/image";
import Link from "next/link";

export default function HomePartners(props: any) {
  const { partners } = props;
  // console.log(partners);
  return (
    <>
      <section className="mb-24">
        <div className="max-w-[100%] m-[40px]">
          <p className="bdy-txt-3 text-center md:mb-[5rem]">
            {partners?.subTitle}
          </p>
          <div className="grid md:grid-cols-6 grid-cols-12 md:gap-8 gap-4">
            {partners?.partners?.map((partner: any, index: any) => (
              <div key={index} className="col-span-6 md:col-span-1">
                <Link href={partner?.link} target="_blank" rel="noreferrer">
                  <Image
                    src={handleImgResponse(partner?.image)}
                    alt="partners"
                    width={200}
                    height={100}
                  />
                </Link>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}
