"use client";

import Markdown from "@/utils/Markdown";
import { handleImgResponse } from "@/utils/utility";
import Image from "next/image";
import { useState } from "react";
import { Autoplay, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";
import KlevrCTA from "../Shared/CTA/KlevrCTA";
import useIsMobile from "../Shared/Media/hooks";

export default function HomeBanner(props: any) {
  const { banners } = props;
  const isMobile = useIsMobile();
  const [, setSwiper] = useState<any>();
  return (
    <>
      <div className="relative h-[680px] w-full">
        <Swiper
          spaceBetween={1}
          slidesPerView={1}
          slidesPerGroup={1}
          pagination={{
            clickable: true,
            el: ".swiper-pagination-home",
            type: "bullets",
            enabled: true,
            bulletClass: "swiper-pagination-bullet",
            bulletActiveClass: "swiper-pagination-bullet-active",
          }}
          modules={[Autoplay, Pagination]}
          autoplay={{
            delay: 4000,
            disableOnInteraction: false,
          }}
          loop={false}
          keyboard={{
            enabled: true,
          }}
          onSwiper={(swiper: any) => setSwiper(swiper)}
          className="w-full h-full"
        >
          {banners.map((banner: any, index: number) => (
            <SwiperSlide key={index}>
              <div
                className={`w-full ${!isMobile ? "h-full" : ""
                  }  relative bg-cover bg-no-repeat`}
                style={{
                  backgroundImage: !isMobile
                    ? `url(${handleImgResponse(banner?.desktopBg)})`
                    : "none",
                }}
              >
                <div className="cu-container py-24">
                  <h1 className="text-white">
                    <Markdown>{banner.title}</Markdown>
                  </h1>
                  <div
                    className={`${!isMobile ? "text-white" : "text-black"
                      } py-8 bdy-txt-5`}
                  >
                    <Markdown>{banner.text}</Markdown>
                  </div>
                  <KlevrCTA
                    text={banner?.button?.text}
                    link={banner?.button?.link}
                    externalLink={banner?.button?.externalLink}
                    isButton={true}
                    cls="btn-secondary"
                  />
                </div>
              </div>
              {isMobile && (
                <Image
                  src={handleImgResponse(banner?.mobileBg)}
                  width={400}
                  height={400}
                  className="w-full h-[400px]"
                  alt="bg"
                />
              )}
            </SwiperSlide>
          ))}
        </Swiper>
        <div
          className="swiper-pagination-home"
          style={{
            position: "relative",
            display: "flex",
            justifyContent: "start",
            top: "-70px",
            left: "100px",
            zIndex: 100,
          }}
        ></div>
      </div>
    </>
  );
}
