"use client";
import { handleImgResponse } from "@/utils/utility";
import { useEffect, useState } from "react";

interface KleverImageProps {
  desktopBg?: string;
  mobileBg?: string;
  cls?: string;
  children?: any;
}

const isMobileDevice = () => {
  return window?.innerWidth <= 768;
};

const KlevrBg = (props: KleverImageProps) => {
  const { desktopBg, mobileBg, cls, children } = props;
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setIsMobile(isMobileDevice());
    const handleResize = () => {
      setIsMobile(isMobileDevice());
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const backgroundStyle = {
    backgroundImage:
      isMobile && handleImgResponse(mobileBg)
        ? `url(${handleImgResponse(mobileBg)})`
        : handleImgResponse(desktopBg)
        ? `url(${handleImgResponse(desktopBg)})`
        : undefined,
  };

  return (
    <div className={`w-full relative  ${cls}`} style={backgroundStyle}>
      {children}
    </div>
  );
};

export default KlevrBg;
