"use client";

import Markdown from "../../utils/Markdown";
import { handleImgResponse } from "@/utils/utility";
import Image from "next/image";
import Link from "next/link";

export default function HomeBusiness(props: any) {
  const { business, news } = props;

  return (
    <>
      <section>
        <div className="max-w-[100%] m-[40px] md:py-24 flex flex-col md:flex-row">
          <div className="max-lg:top-[-110px] lg:bottom-0 left-[4%]">
            <Image
              src={handleImgResponse(business?.image)}
              className="w-full h-full relative"
              alt="business"
              width={400}
              height={300}
            />
          </div>

          <div className="bg-black relative w-[100%] lg:w-[100%] py-10 lg:pt-[220px] px-[30px] md:w-[73%] lg:pt-[50px] lg:pr-[150px] lg:pl-[100px]">
            <div
              className="hidden md:block w-0 h-0
          border-l-[60px] border-l-transparent
          border-b-[30px] border-b-white
          border-r-[60px] border-r-transparent
          absolute bottom-[-1px] right-[20%]"
            ></div>
            <p className="text-white bdy-text-3 mb-6">{business?.subTitle}</p>
            <h4 className="text-white mb-6">
              <Markdown>{business?.title}</Markdown>
            </h4>
            <div className="text-white bdy-text-5">
              <Markdown>{business?.text}</Markdown>
            </div>

            <Link href={business?.button?.link}>
              <button className="btn-secondary mt-[40px] py-8 md:py-4">
                {business?.button?.text}
              </button>
            </Link>
          </div>
        </div>
      </section>

      <section className="md:pb-[60px]">
        <div className="max-w-[100%] m-[40px]">
          <div className="grid grid-cols-12">
            <div className="col-span-12 lg:col-span-3  mb-8 sm:mb-2">
              <div className="lg:max-w-[250px]">
                <h4>
                  <Markdown>{news?.title}</Markdown>
                </h4>
                <Link href={news?.button?.link}>
                  <button className="btn-primary mt-4">
                    {news?.button?.text}
                  </button>
                </Link>
              </div>
            </div>

            {news?.articles?.map((item: any, index: any) => (
              <div key={index} className="col-span-12 lg:col-span-3">
                <div className="bdy-txt-4 mb-4">{item?.date}</div>
                <div className="bdy-txt-1">
                  <Markdown>{item?.title}</Markdown>
                </div>
                <Link href={`/latest/${item?.category}/${item?.slug}`}>
                  <button className="btn-third mt-[20px]">
                    <Image
                      src="/assets/icons/arrow-right.png"
                      className="mb-[7px] h-[13px] w-[13px]"
                      alt="Red Arrow Right"
                      width={300}
                      height={300}
                    />
                    <span className="h-[36px] hover:border-b-[4px] hover:border-red">
                      more
                    </span>
                  </button>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}
