"use client";
import { useState } from "react";
import Markdown from "@/utils/Markdown";
import SwiperCore from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Keyboard, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { handleImgResponse } from "@/utils/utility";
import Image from "next/image";
import Calculator from "../Shared/common/Calculator";
import KlevrCTA from "../Shared/CTA/KlevrCTA";

SwiperCore.use([Pagination, Keyboard]);
export default function HomeSendMoney(props: any) {
  const [, setSwiper] = useState<any>();
  const { sendMoney } = props;
  return (
    <>
      <div className="md:cu-container pt-24">
        <p className="bdy-txt-3 text-center">{sendMoney?.subtitle}</p>
        <h3 className="text-center pb-12">
          <Markdown>{sendMoney?.title}</Markdown>
        </h3>
        <div className="xl:grid flex flex-col grid-cols-1 md:grid-cols-12 gap-8 md:py-16">
          <div className="flex flex-col col-span-4 m-[40px] md:m-0">
            <div className="col col-span-1 flex justify-center">

              <Markdown cls="pt-6 bdy-txt-5">{sendMoney?.text}</Markdown>
            </div>
            <div className="mt-6">
              <Image
                src="/assets/images/shared/MoneyGram.png"
                alt="logo"
                width={250}
                height={250}
              />
            </div>
          </div>
          <div className="col col-span-4 w-screen md:w-[400px] flex justify-center md:justify-end lg:ml-[20%] md:ml-0">
            <Calculator />
          </div>
          <div className="col col-span-4 z-10 md:-mt-[6rem] md:-mr-[5rem]">
            <Image
              src={handleImgResponse(sendMoney?.image)}
              alt={sendMoney?.title}
              width={500}
              height={600}
              className="left-0 -top-[50px] md:-left-[150px] md:-top-[135px] "
            />
          </div>
        </div>
        <div className="pt-12">
          <p className="bdy-txt-3 text-center py-6 uppercase">
            {sendMoney?.subtitle2}
          </p>
          <div className=" max-md:hidden flex flex-wrap justify-center">
            {sendMoney?.ways?.map((card: any, index: number) => (
              <div key={index} className="p-2 ">
                <div className="bg-white shadow-cu w-[400px] h-[350px] relative">
                  <div className="traingle md:left-[20%] left-[15%] border-l-[25px] border-r-[25px] border-t-[15px] border-t-[#f1f1f1]"></div>
                  <div className="w-4/5 mx-auto flex flex-col items-center">
                    <p className="bdy-txt-1 text-center pt-6 mt-[3rem]">
                      {card?.title}
                    </p>

                    <Markdown cls="bdy-txt-5 text-center pt-4">
                      {card?.text}
                    </Markdown>
                    <div className="text-center pt-6">
                      <KlevrCTA
                        link={card?.cta?.link}
                        externalLink={card?.cta?.externalLink}
                      >
                        <button className="btn-third">
                          <Image
                            src="/assets/icons/arrow-right.png"
                            className="h-[13px] w-[13px]"
                            alt="Red Arrow Right"
                            width={300}
                            height={300}
                          />
                          <span className="text-base">{card?.cta?.text}</span>
                        </button>
                      </KlevrCTA>
                    </div>
                    <div
                      className={`absolute bottom-0 ${index === 0 ? "left-0" : "right-0"
                        }`}
                    >
                      <Image
                        src={handleImgResponse(card?.image)}
                        alt={card?.title}
                        width={index === 0 ? 100 : 200}
                        height={index === 0 ? 100 : 200}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className="md:hidden">
            <Swiper
              spaceBetween={1}
              slidesPerView={1}
              slidesPerGroup={1}
              pagination={{
                clickable: true,
                el: ".swiper-pagination-sm",
                type: "bullets",
                enabled: true,
                bulletClass: "swiper-pagination-bullet",
                bulletActiveClass: "swiper-pagination-bullet-active",
              }}
              modules={[Pagination]}
              loop={true}
              keyboard={{
                enabled: true,
              }}
              onSwiper={(swiper: any) => setSwiper(swiper)}
            >
              {sendMoney?.ways?.map((card: any, index: number) => (
                <SwiperSlide key={index}>
                  <div key={index} className="p-2 ">
                    <div className="bg-white shadow-cu w-[400px] h-[350px] relative">
                      <div className="traingle md:left-[20%] left-[15%] border-l-[25px] border-r-[25px] border-t-[15px] border-t-[#f1f1f1]"></div>
                      <div className="w-4/5 mx-auto flex flex-col items-center">
                        <p className="bdy-txt-1 text-center pt-6 mt-[3rem]">
                          {card?.title}
                        </p>
                        <p className="bdy-txt-5 text-center pt-4">
                          {card?.text}
                        </p>
                        <div className="text-center pt-6">
                          <KlevrCTA
                            link={card?.cta?.link}
                            externalLink={card?.cta?.externalLink}
                          >
                            <button className="btn-third">
                              <Image
                                src="/assets/icons/arrow-right.png"
                                className="h-[13px] w-[13px]"
                                alt="Red Arrow Right"
                                width={300}
                                height={300}
                              />
                              <span className="text-base">
                                {card?.cta?.text}
                              </span>
                            </button>
                          </KlevrCTA>
                        </div>
                        <div
                          className={`absolute bottom-0 ${index === 0 ? "left-0" : "right-0"
                            }`}
                        >
                          <Image
                            src={handleImgResponse(card?.image)}
                            alt={card?.title}
                            width={index === 0 ? 100 : 200}
                            height={index === 0 ? 100 : 200}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
            <div
              className="swiper-pagination-sm"
              style={{
                position: "relative",
                display: "flex",
                justifyContent: "start",
                top: "30px",
                left: "100px",
                zIndex: 100,
              }}
            ></div>
          </div>
        </div>
      </div>
    </>
  );
}
