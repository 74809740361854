import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/Home/HomeBanner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/Home/HomeBusiness.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/Home/HomePartners.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/Home/HomeSendMoney.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/Services/ServicesListing.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/Shared/Media/KlevrBg.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/Shared/Media/KlevrImage.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/utils/Markdown.css");
