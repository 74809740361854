"use client";
import Image from "next/image";
import { useEffect, useState } from "react";
import { handleImgResponse } from "../../../utils/utility";
interface KleverImagePorps {
  desktopImage?: any;
  mobileImage?: any;
  alt: string;
  fill?: boolean;
  cls?: string;
  width?: number;
  height?: number;
}
const isMobileDevice = () => {
  return window?.innerWidth <= 768;
};

const KlevrImage = (props: KleverImagePorps) => {
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    setIsMobile(isMobileDevice());
    const handleResize = () => {
      setIsMobile(isMobileDevice());
    };
    window.addEventListener("resize", handleResize);
    // Cleanup function
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const { desktopImage, mobileImage, cls, width, height, fill } = props;
  const fullMobileImage = handleImgResponse(mobileImage);
  const fullDesktopImage = handleImgResponse(desktopImage);

  return (
    <>
      {mobileImage && mobileImage?.url?.length > 0 && isMobile ? (
        <>
          {fill ? (
            <Image
              src={fullMobileImage}
              alt={props.alt}
              height={mobileImage?.height}
              width={mobileImage?.width}
              layout="fill"
              className={cls}
            />
          ) : (
            <Image
              src={fullMobileImage}
              alt={props.alt}
              height={mobileImage?.height}
              width={mobileImage?.width}
              className={cls}
            />
          )}
        </>
      ) : (
        <>
          {fill ? (
            <Image
              src={fullDesktopImage}
              height={height}
              width={width}
              alt={props.alt}
              layout="fill"
              className={cls}
            />
          ) : (
            <Image
              src={fullDesktopImage}
              alt={props.alt}
              height={height}
              width={width}
              className={cls}
            />
          )}
        </>
      )}
    </>
  );
};
export default KlevrImage;
