interface CTAProps {
    text?: string;
    link: string;
    isButton?: boolean;
    externalLink?: boolean;
    cls?: string;
    children?: any;
}

const KlevrCTA = (props: CTAProps) => {
    const { text, link, isButton = false, externalLink = false, cls, children } = props;

    return (
        <>
            {children ? (<>
                {

                    <a href={link} className={`${cls}`} target={externalLink ? "_blank" : "_self"}>
                        {children}
                    </a>
                }
            </>) : (<>
                {
                    isButton ? (
                        <button className={`${cls}`} onClick={() => window.open(link, externalLink ? "_blank" : "_self")}>
                            {text}
                        </button >
                    ) : (
                        <a href={link} className={` ${cls}`} target={externalLink ? "_blank" : "_self"}>
                            {text}
                        </a>
                    )}
            </>
            )
            }

        </>
    )
};
export default KlevrCTA;
