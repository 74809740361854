"use client";

import { handleImgResponse } from "@/utils/utility";
import Markdown from "../../utils/Markdown";
import "swiper/css";
import "swiper/scss/pagination";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Keyboard } from "swiper/modules";
import SwiperCore from "swiper";
import { useState } from "react";
import Link from "next/link";
SwiperCore.use([Pagination, Keyboard]);
export default function ServicesListing(props: any) {
    const { services, subtitle, title, viewAllLink = "" } = props;
    const [, setSwiper] = useState<any>();

    return (
        <>
            <section>
                <div className="max-w-[100%] md:pt-24 m-[40px] md:pb-24">
                    <p className="bdy-txt-3 text-center">{subtitle}</p>
                    <h3 className="text-center pb-12">
                        <Markdown>{title}</Markdown>
                    </h3>

                    <div className="grid grid-cols-4 gap-4 max-md:hidden pt-[70px]">
                        {services
                            ?.filter((item: any) => item.displayInLanding !== false)?.map((item: any, index: any) => (
                                <a
                                    href={`/services/${item?.slug}`}
                                    className="no-underline text-inherit hover:border-none col-span-2 xl:col-span-1"
                                    key={index}
                                >
                                    <div
                                        key={index}
                                        className={` flex-grow shadow-cu h-[300px] bg-center bg-no-repeat rounded-[30px] md:w-4/5 bg-[length:90%_85%] ${index < 4 && index % 2 === 1 ? "xl:mt-[65px]" : ""
                                            } pl-[30px] pb-[30px]  ${index === 4 || index === 6 ? "xl:-mt-[65px]" : ""
                                            }`}
                                        style={{
                                            backgroundImage:
                                                "url(" + handleImgResponse(item?.image) + ")",
                                        }}
                                    >
                                        <div className="pt-4 bdy-txt-1 font-black w-6">
                                            <Markdown>{item?.name}</Markdown>
                                        </div>
                                    </div>
                                </a>
                            ))}
                        {viewAllLink && (
                            <Link
                                href={viewAllLink || ""}
                                className="no-underline text-inherit hover:border-none"
                            >
                                <div
                                    className={`col-span-1 flex-grow shadow-cu h-[250px] bg-center bg-no-repeat rounded-[30px] md:w-4/5 bg-[length:90%_85%] flex justify-center items-center`}
                                    style={{
                                        backgroundImage: "url(assets/images/shared/linear-rect.png)",
                                    }}
                                >
                                    <p className="bdy-txt-1 text-center text-white">View All</p>
                                </div>
                            </Link>
                        )}

                    </div>
                </div>
            </section>

            <div className="md:hidden pb-[50px]">
                <Swiper
                    spaceBetween={1}
                    slidesPerView={1}
                    slidesPerGroup={1}
                    style={{ paddingBottom: "30px" }}
                    pagination={{
                        clickable: true,
                        el: ".swiper-pagination",
                        type: "bullets",
                        enabled: true,
                        bulletClass: "swiper-pagination-bullet",
                        bulletActiveClass: "swiper-pagination-bullet-active",
                    }}
                    modules={[Pagination]}
                    loop={true}
                    keyboard={{
                        enabled: true,
                    }}
                    onSwiper={(swiper: any) => setSwiper(swiper)}
                >
                    {services?.map((item: any, index: any) => (
                        <SwiperSlide key={index} style={{ paddingBottom: "10px" }}>
                            <a
                                href={`/services/${item?.slug}`}
                                className="no-underline text-inherit hover:border-none "
                                key={index}
                            >
                                <div
                                    key={index}
                                    className={`mx-auto flex-grow shadow-cu h-[275px] bg-center bg-no-repeat rounded-[30px] w-[300px]
                   pl-[25px] pb-[30px]  
                  `}
                                    style={{
                                        backgroundImage:
                                            "url(" + handleImgResponse(item?.image) + ")",
                                    }}
                                >
                                    <div className="pt-4 bdy-txt-1">
                                        <Markdown>{item?.name}</Markdown>
                                    </div>
                                </div>
                            </a>
                        </SwiperSlide>
                    ))}
                </Swiper>
                <div
                    className="swiper-pagination"
                    style={{
                        position: "relative",
                        display: "flex",
                        justifyContent: "center",
                        top: "15px",
                    }}
                ></div>
            </div>
        </>
    );
}
